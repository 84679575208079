(function () {
  var closeNav, openNav;

  openNav = function () {
    $("div.sidenav").css("width", "180px");
  };

  /* Set the width of the side navigation to 0 */

  closeNav = function () {
    $("div.sidenav").css("width", "0px");
  };

  $(document).on("turbolinks:load", function () {
    $("div.navigator").click(function (event) {
      openNav();
      event.preventDefault();
    });
    $("a#close").click(function (event) {
      closeNav();
      event.preventDefault();
    });
  });
}.call(this));
