$(document).on('turbolinks:load', function () {
	const b = document.cookie.match(
		'(^|;)\\s*' + 'cookieconsent_status' + '\\s*=\\s*([^;]+)'
	)

	if (!b) {
		window.cookieconsent.initialise({
			container: document.getElementById('container'),
			content: {
				message:
					'Indem Sie Ihre Navigation auf dieser Website fortsetzen, akzeptieren Sie die Verwendung von Cookies, die insbesondere dazu dienen, Ihnen personalisierte Funktionen anzubieten und Ihre Besuche auf der Website zu erfassen.',
				dismiss: 'Ich habe verstanden',
				link: 'Mehr erfahren',
			},
			palette: {
				popup: { background: '#fff' },
				button: { background: '#323663' },
			},
			revokable: false,
			law: {
				regionalLaw: false,
			},
			location: true,
			hasTransition: false,
		})
	}
})
