(function () {
  window.init_map = function () {
    var center,
      contentString,
      div,
      hamburg,
      infowindow,
      magdeburg,
      map,
      mapOptions,
      marker,
      rostock;
    center = new google.maps.LatLng(53.425988, 11.509218);
    rostock = new google.maps.LatLng(54.154149, 12.125305);
    hamburg = new google.maps.LatLng(53.50045, 9.97394);
    magdeburg = new google.maps.LatLng(52.40575, 11.24895);
    mapOptions = {
      zoom: 7,
      center: center,
      disableDefaultUI: true,
    };
    div = $("div.map_canvas");
    map = new google.maps.Map(div[0], mapOptions);
    contentString = "<div>" + "Büro Rostock" + "</div>";
    infowindow = new google.maps.InfoWindow({
      content: contentString,
    });
    marker = new google.maps.Marker({
      position: rostock,
      map: map,
      title: "Labor & Büro Rostock",
    });
    infowindow.open(map, marker);
    contentString = "<div>" + "Büro Hamburg" + "</div>";
    infowindow = new google.maps.InfoWindow({
      content: contentString,
    });
    marker = new google.maps.Marker({
      position: hamburg,
      map: map,
      title: "Büro Hamburg",
    });
    infowindow.open(map, marker);
    contentString = '<div class="info_window">' + "Büro Magdeburg" + "</div>";
    infowindow = new google.maps.InfoWindow({
      content: contentString,
    });
    marker = new google.maps.Marker({
      position: magdeburg,
      map: map,
      title: "Büro Magdeburg",
    });
    return infowindow.open(map, marker);
  };
}.call(this));
